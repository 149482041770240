@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$color-yellow: #F1BE48;
$color-teal-dark: #203E40;
$color-teal: #2b7079;
$color-teal-50: tint-color($color-teal, 50%);
$color-teal-80: tint-color($color-teal, 20%);
$color-purple-dark: #423749;
$color-purple: #5C4B64;
$color-purple-50: tint-color($color-purple, 50%);
$color-purple-80: tint-color($color-purple, 20%);
$color-charcoal: #404042;
$color-light-grey: #F1F1F1;
$color-cool-grey: #D6D6D6; 
$font-circular: "Circular-Regular","Trebuchet MS","Gill Sans","Helvetica Neue",arial,sans-serif;
$prefix: "nucleus-video-module";

/*
  HACK: Workaround for a problem where tabbing through the YouTube embeds with the keyboard would cause the page to scroll up
  and stop the video. This needs to be applied at least at the level of the page this module appears in;HACK obviously
  there are implications for setting it across the whole site and it's probably not a good idea.
  See https://stackoverflow.com/a/78143995/1991476
*/
:root {
  scroll-padding: 0;
}

@keyframes #{$prefix}-swiper {
  0% {
    animation-timing-function: ease-in;
  }

  20% {
    transform: translateX(-20px);
    animation-timing-function: ease-out;
  }

  40% {
    transform: none;
    animation-timing-function: ease-in;
  }

  60% {
    transform: translateX(20px);
    animation-timing-function: ease-out;
  }

  80% {
    transform: none;
  }
}

@keyframes #{$prefix}-bounce {
  0% {
    transform: none;
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: none;
  }
}

.#{$prefix} {
  font-family: $font-circular;
}

.#{$prefix}__scroll-track {
  height: 600vh;
  background: $color-teal;
}

.#{$prefix}__scroller {
  height: 100dvh;
  position: sticky;
  top: 0;
}

.#{$prefix}__hero__container {
  padding: 0 $spacer * 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: $color-teal;
  z-index: 1;
}

.#{$prefix}__hero__backdrop-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: max(100vw, 100vh);
  height: max(100vw, 100vh);
}

.#{$prefix}__hero__backdrop {
  background: $color-yellow;
  position: absolute;
  inset: -15%;
  border-radius: 25%;
}

.#{$prefix}__hero__title {
  margin-bottom: $spacer * 2;
  font-size: 32px;

  @include media-breakpoint-up(lg) {
    font-size: 48px;
  }
}

.#{$prefix}__hero__intro-copy {
  max-width: 600px;
  font-family: $font-circular;
}

.#{$prefix}__hero__app-icon {
  margin: 0 auto $spacer * 3;
  display: block;
  width: 110px;
  height: 110px;

  @include media-breakpoint-up(lg) {
    margin: 0 auto $spacer * 5;
    width: 200px;
    height: 200px;
  }
}

.#{$prefix}__hero__scroll-down {
  border: 0;
  padding: 0;
  background: transparent;
  margin-top: $spacer * 3;
  animation: #{$prefix}-bounce 3s infinite ease-in-out;
  width: 40px;

  @include media-breakpoint-up(lg) {
    margin-top: $spacer * 5;
    width: 50px;
  }
}

.#{$prefix}__hero__split-line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(var(--x-scale));
  background: white;
  width: 2px;
  height: 100vh;

  --x-scale: 2;

  @include media-breakpoint-up(lg) {
    --x-scale: 4;
  }
}

.#{$prefix}__audience-selector__container {
  position: relative;
  z-index: 1;
  height: 100%;
}

.#{$prefix}__audience-selector__split {
  display: flex;
  align-items: stretch;
  position: relative;
  height: 100%;
  overflow: hidden;
  user-select: none;

  /*
    HACK: :dir(rtl) would be more appropriate here but support is still new and Parcel's postprocessing seems
    to be doing a weird polyfill transform on this to use the lang attribute instead.
    Looking at Cochlear's approach to RTL and the PostCSS polyfill I think this is the appropriate way
    (See https://github.com/csstools/postcss-plugins/tree/main/plugins/postcss-dir-pseudo-class)
  */
  [dir="rtl"] & {
    /*
      Note: we are forcing the flow to be the same in RTL as LTR, which is not strictly correct,
      but Split.js doesn't support RTL properly
      (See https://github.com/nathancahill/split/issues/92#issuecomment-320522564)
      Since the order of the split is effectively arbitrary, reversing is sufficient;
    */
    flex-direction: row-reverse
  }
}

.#{$prefix}__audience-selector__audience-button {
  position: relative;
  color: white;
  text-align: center;
  border: 0;
  background: 0;
  padding: 0;
}

.#{$prefix}__audience-selector__audience-name {
  color: white;
  margin: 0;
  font-size: 24px;

  @include media-breakpoint-up(lg) {
    font-size: 48px;
  }
}

.#{$prefix}__audience-selector__prompt {
  position: absolute;
  bottom: $spacer * 2;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: $spacer * 2;
  width: 325px;
  z-index: 2;
  font-size: 18px;
  background: rgb(255 255 255 / 30%);
  backdrop-filter: blur(3px);
  border-radius: 15px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  pointer-events: none;

  &.is-showing {
    opacity: 1;
  }
}

.#{$prefix}__audience-selector__tap-and-drag-icon {
  animation: #{$prefix}-swiper 4s infinite;
}

.#{$prefix}__audience-selector__gutter {
  position: relative;
  z-index: 1;
  cursor: col-resize;
  
  &::after {
    content: "";
    position: absolute;
    inset: 0 -1px;
    background: white;
    transition: all 0.2s ease-out
  }

  &.is-selected {
    &::after {
      opacity: 0;
    }

    .#{$prefix}__audience-selector__handle {
      opacity: 0.5;
    }
  }
}

.#{$prefix}__audience-selector__gutter-click-target {
  width: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  transition: all 0.2s ease-out;
}

.#{$prefix}__audience-selector__handle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter:  drop-shadow(0 0 8px rgb(0 0 0 / 15%));
  transition: all 0.2s ease-out;
}

/*
  This is absolute because of a positioning trick to give us a flex-stretched space to fill with the ReactPlayer
  depending on how much space is available in the parent. If we don't take the player out of the document flow, the
  player will influence the size of the parent, which we don't want.
*/
.#{$prefix}__audience-selector__video-wrapper {
  position: absolute;
  inset: 0;
  overflow: hidden;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    min-width: 50vw;
  }
}

.#{$prefix}__audience-selector__video-sizer {
  margin: 0 auto;
  aspect-ratio: 432 / 540;
  width: 100%;
  max-height: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
    max-height: none;
    height: 100%;
    max-width: 100%;
  }
}

.#{$prefix}__audience-selector__chapters {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  min-width: 100vw;
  overflow: hidden;
  margin-right: -1px;

  @include media-breakpoint-up(md) {
    min-width: 60vw;
  }
}

.#{$prefix}__audience-selector__chapter-button {
  flex-grow: 1;
  padding: $spacer * 0.75;
  border: 0;
  background: $color-cool-grey;
  display: flex;
  flex-direction: column;
  gap: $spacer * 0.25;
  color: $color-charcoal;
  border-right: 1px solid transparent;
  font-size: 14px;
  text-align: start;
  transition: all 300ms ease-out;

  .#{$prefix}__audience-selector__chapters--audience-0 & {
    background: $color-teal-50;
    color: $color-teal-dark;
    border-color: $color-teal-80;

    &.is-active {
      background: $color-teal-80;
      color: white;
    }
  }

  .#{$prefix}__audience-selector__chapters--audience-1 & {
    background: $color-purple-50;
    color: $color-purple-dark;
    border-color: $color-purple-80;

    &.is-active {
      background: $color-purple-80;
      color: white;
    }
  }
}

.#{$prefix}__audience-selector__chapter-button-title {
  line-height: 125%;
  font-size: 16px;
  text-decoration: underline;
  margin-bottom: $spacer * 0.75;
}
