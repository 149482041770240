:root {
  scroll-padding: 0;
}

@keyframes nucleus-video-module-swiper {
  0% {
    animation-timing-function: ease-in;
  }

  20% {
    animation-timing-function: ease-out;
    transform: translateX(-20px);
  }

  40% {
    animation-timing-function: ease-in;
    transform: none;
  }

  60% {
    animation-timing-function: ease-out;
    transform: translateX(20px);
  }

  80% {
    transform: none;
  }
}

@keyframes nucleus-video-module-bounce {
  0% {
    transform: none;
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: none;
  }
}

.nucleus-video-module {
  font-family: Circular-Regular, Trebuchet MS, Gill Sans, Helvetica Neue, arial, sans-serif;
}

.nucleus-video-module__scroll-track {
  background: #2b7079;
  height: 600vh;
}

.nucleus-video-module__scroller {
  height: 100dvh;
  position: sticky;
  top: 0;
}

.nucleus-video-module__hero__container {
  text-align: center;
  z-index: 1;
  background: #2b7079;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.nucleus-video-module__hero__backdrop-position {
  width: max(100vw, 100vh);
  height: max(100vw, 100vh);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.nucleus-video-module__hero__backdrop {
  background: #f1be48;
  border-radius: 25%;
  position: absolute;
  inset: -15%;
}

.nucleus-video-module__hero__title {
  margin-bottom: 2rem;
  font-size: 32px;
}

@media (width >= 992px) {
  .nucleus-video-module__hero__title {
    font-size: 48px;
  }
}

.nucleus-video-module__hero__intro-copy {
  max-width: 600px;
  font-family: Circular-Regular, Trebuchet MS, Gill Sans, Helvetica Neue, arial, sans-serif;
}

.nucleus-video-module__hero__app-icon {
  width: 110px;
  height: 110px;
  margin: 0 auto 3rem;
  display: block;
}

@media (width >= 992px) {
  .nucleus-video-module__hero__app-icon {
    width: 200px;
    height: 200px;
    margin: 0 auto 5rem;
  }
}

.nucleus-video-module__hero__scroll-down {
  background: none;
  border: 0;
  width: 40px;
  margin-top: 3rem;
  padding: 0;
  animation: 3s ease-in-out infinite nucleus-video-module-bounce;
}

@media (width >= 992px) {
  .nucleus-video-module__hero__scroll-down {
    width: 50px;
    margin-top: 5rem;
  }
}

.nucleus-video-module__hero__split-line {
  transform: translate(-50%, -50%) scaleX(var(--x-scale));
  --x-scale: 2;
  background: #fff;
  width: 2px;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (width >= 992px) {
  .nucleus-video-module__hero__split-line {
    --x-scale: 4;
  }
}

.nucleus-video-module__audience-selector__container {
  z-index: 1;
  height: 100%;
  position: relative;
}

.nucleus-video-module__audience-selector__split {
  -webkit-user-select: none;
  user-select: none;
  align-items: stretch;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

[dir="rtl"] .nucleus-video-module__audience-selector__split {
  flex-direction: row-reverse;
}

.nucleus-video-module__audience-selector__audience-button {
  color: #fff;
  text-align: center;
  background: 0;
  border: 0;
  padding: 0;
  position: relative;
}

.nucleus-video-module__audience-selector__audience-name {
  color: #fff;
  margin: 0;
  font-size: 24px;
}

@media (width >= 992px) {
  .nucleus-video-module__audience-selector__audience-name {
    font-size: 48px;
  }
}

.nucleus-video-module__audience-selector__prompt {
  color: #fff;
  z-index: 2;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  opacity: 0;
  pointer-events: none;
  background: #ffffff4d;
  border-radius: 15px;
  width: 325px;
  padding: 2rem;
  font-size: 18px;
  transition: opacity .2s ease-out;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}

.nucleus-video-module__audience-selector__prompt.is-showing {
  opacity: 1;
}

.nucleus-video-module__audience-selector__tap-and-drag-icon {
  animation: 4s infinite nucleus-video-module-swiper;
}

.nucleus-video-module__audience-selector__gutter {
  z-index: 1;
  cursor: col-resize;
  position: relative;
}

.nucleus-video-module__audience-selector__gutter:after {
  content: "";
  background: #fff;
  transition: all .2s ease-out;
  position: absolute;
  inset: 0 -1px;
}

.nucleus-video-module__audience-selector__gutter.is-selected:after {
  opacity: 0;
}

.nucleus-video-module__audience-selector__gutter.is-selected .nucleus-video-module__audience-selector__handle {
  opacity: .5;
}

.nucleus-video-module__audience-selector__gutter-click-target {
  width: 50px;
  height: 100%;
  transition: all .2s ease-out;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nucleus-video-module__audience-selector__handle {
  filter: drop-shadow(0 0 8px #00000026);
  transition: all .2s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nucleus-video-module__audience-selector__video-wrapper {
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

@media (width >= 768px) {
  .nucleus-video-module__audience-selector__video-wrapper {
    min-width: 50vw;
  }
}

.nucleus-video-module__audience-selector__video-sizer {
  aspect-ratio: 432 / 540;
  width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

@media (width >= 768px) {
  .nucleus-video-module__audience-selector__video-sizer {
    width: auto;
    max-width: 100%;
    height: 100%;
    max-height: none;
  }
}

.nucleus-video-module__audience-selector__chapters {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  min-width: 100vw;
  margin-right: -1px;
  display: grid;
  overflow: hidden;
}

@media (width >= 768px) {
  .nucleus-video-module__audience-selector__chapters {
    min-width: 60vw;
  }
}

.nucleus-video-module__audience-selector__chapter-button {
  color: #404042;
  text-align: start;
  background: #d6d6d6;
  border: 0;
  border-right: 1px solid #0000;
  flex-direction: column;
  flex-grow: 1;
  gap: .25rem;
  padding: .75rem;
  font-size: 14px;
  transition: all .3s ease-out;
  display: flex;
}

.nucleus-video-module__audience-selector__chapters--audience-0 .nucleus-video-module__audience-selector__chapter-button {
  color: #203e40;
  background: #95b8bc;
  border-color: #558d94;
}

.nucleus-video-module__audience-selector__chapters--audience-0 .nucleus-video-module__audience-selector__chapter-button.is-active {
  color: #fff;
  background: #558d94;
}

.nucleus-video-module__audience-selector__chapters--audience-1 .nucleus-video-module__audience-selector__chapter-button {
  color: #423749;
  background: #aea5b2;
  border-color: #7d6f83;
}

.nucleus-video-module__audience-selector__chapters--audience-1 .nucleus-video-module__audience-selector__chapter-button.is-active {
  color: #fff;
  background: #7d6f83;
}

.nucleus-video-module__audience-selector__chapter-button-title {
  margin-bottom: .75rem;
  font-size: 16px;
  line-height: 125%;
  text-decoration: underline;
}

/*# sourceMappingURL=index.91a5139e.css.map */
